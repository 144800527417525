import { SALON_SLUG } from './router'

// 現在のURLからアクセスしているアプリ名を取得し、ナビゲーションボタンに色付けする
/* export const bs_Array: { dropdown: Dropdown[]; collapse: Collapse[] } = {
  dropdown: [],
  collapse: [],
};*/



export const paintNavItem = () => {
  /*const adr = location.pathname.split('/')[1];

  if (document.querySelector(`#nav-item-${adr}`)) {
    document
      .querySelector<HTMLElement>(`#nav-item-${adr}`)
      ?.classList.add('active');
  }*/


  // 店舗選択ドロップダウン。 Userが所属する会社のサロンが全て表示される
  const modeChoice = document.querySelector<HTMLElement>("#modeChoice")!
  Array.from(modeChoice.querySelectorAll<HTMLAnchorElement>('.dropdown-item')).forEach(el => {
    if (`mode_${SALON_SLUG}` === el.id) modeChoice.querySelector<HTMLElement>(".modeLabel")!.innerText = el.innerText
    const serchWord = new RegExp("\/" + SALON_SLUG + "\/")
    el.href = location.pathname.replace(serchWord, `/${el.id.replace("mode_", "")}/`)
  })
};



// import { Input } from 'mdb-ui-kit'; // module
import { paintNavItem } from '../scripts/nav_bar';
import { paintSideNavButton } from '../scripts/back_office/sidenav';
import { Collapse, Dropdown } from 'bootstrap'; // <- 必須
import { APP_NAME, SALON_SLUG } from 'scripts/router';

if (
  SALON_SLUG === 'all' &&
  (['schedule', 'reservations', 'cashier'].includes(APP_NAME))
) {
  alert(
    '全店管理モードになっています。スケジュール・売上を編集するには店舗を選択してください。',
  );
}

window.addEventListener('DOMContentLoaded', (e) => {
  paintNavItem();
  paintSideNavButton();
});


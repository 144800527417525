import { APP_NAME } from '../router';

// 現在のURLからアクセスしているアプリ名を取得し、ナビゲーションボタンに色付けする
export const paintSideNavButton = () => {
  const address_id_map = {
    'back-office': {
      '': 'back-office',
      'carte-maker': 'carte-maker',
      cashier: 'casier',
      reservations: 'reservations',
      products: 'products',
      stocker: 'stocker',
      management: 'management',
      users: 'users',
      schedule: 'schedule',
    },
  };

  const sidenav_menus = Array.from(
    document.getElementById('sidenav')!.querySelectorAll('.nav-link'),
  );
  // 初期化
  sidenav_menus.forEach((element) => {
    element.classList.remove('active');
  });

  if (!['salon-settings', 'user-settings'].includes(APP_NAME)) {
    if (Object.keys(address_id_map['back-office']).includes(APP_NAME)) {
      const nav_id = `navLink-${APP_NAME}`; // console.log(nav_id, pathArr[3]);
      // nav menu に色付け
      document.getElementById(nav_id)?.classList.add('active');
    }
  }
};

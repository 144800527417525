export const pathArr = location.pathname.split('/').filter((val) => val !== '');
export const COMPANY_SLUG = pathArr[1];
export const SALON_SLUG = pathArr[2];
export const APP_NAME = pathArr[3];
export const VIEW_NAME = pathArr[4];

const routeBackOffice = `/back-office/${COMPANY_SLUG}/${SALON_SLUG}`;
const routeApp = `${routeBackOffice}/${APP_NAME}`;

export const routResizedImageRegister = {
  code: pathArr[pathArr.length - 1],
  get path() {
    return `${routeApp}/resized-image-register/${this.code}/`;
  },
};

export const routeImageUploader = {
  get changeCustomerImageIndex() {
    return `${routeBackOffice}/carte-maker/change-image-index/`;
  },
  get deleteCustomerImage() {
    return `${routeBackOffice}/carte-maker/image-delete/`;
  },
};

export const routeBackOfficeApp = {
  get pricingTable() {
    return `${routeBackOffice}/pricing-table/`;
  },
};

export const routeCarteMakerApp = {
  get carteId() {
    return pathArr[pathArr.length - 1];
  },
  get carteNotes() {
    return `${routeBackOffice}/carte-maker/api/cartenotes`;
  },
  CustomerApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/carte-maker/api/customer/${pk}/` : `${routeBackOffice}/carte-maker/api/customer/`;
    return endpoint
  },
  CarteApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/carte-maker/api/carte/${pk}/` : `${routeBackOffice}/carte-maker/api/carte/`;
    return endpoint
  },
  CarteDetailContextApi() {
    return `${routeBackOffice}/carte-maker/api/carte-context/`;
  },
  OrderApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/carte-maker/api/order/${pk}/` : `${routeBackOffice}/carte-maker/api/order/`;
    return endpoint
  },
};

// Reservation & Schedule
export const routeCalendarApp = {
  get SalonResources() {
    return `${routeBackOffice}/reservations/api/salon-resources/`;
  },
  get makeReservation() {
    return `${routeBackOffice}/reservations/api/make-reservation/`;
  },
  get reservationList() {
    return `${routeBackOffice}/reservations/api/reservation-list/`;
  },
  get itemList() {
    return `${routeBackOffice}/reservations/api/item-list/`;
  },
  get customerSearch() {
    return `${routeBackOffice}/reservations/api/customer-search/`;
  },
  get scheduleList() {
    return `${routeBackOffice}/schedule/api/schedule-list/`;
  },
  get makeSchedule() {
    return `${routeBackOffice}/schedule/api/make-schedule/`;
  },
};

// << Products >>
export const routeProductsApp = {
  get imageDelete() {
    return `${routeBackOffice}/products/image-delete/`;
  },
  get changeImageIndex() {
    return `${routeBackOffice}/products/change-image-index/`;
  },
  get productList() {
    return `${routeBackOffice}/products/api/item/`;
  },
  productsApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/products/api/item/${pk}/` : `${routeBackOffice}/products/api/item/`;
    return endpoint
  },
  categoriesApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/products/api/category/${pk}/` : `${routeBackOffice}/products/api/category/`;
    return endpoint
  },
  get partChoice() {
    const itemcode = pathArr[pathArr.length - 1];
    return `${routeBackOffice}/products/part-choice/${itemcode}/`;
  },
};

// << Stocker >>
export const routeStockerApp = {
  stockApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/stocker/api/stock/${pk}` : `${routeBackOffice}/stocker/api/stock/`
    return endpoint
  },
  bulkStockApi() {
    return `${routeBackOffice}/stocker/bulk-stock/`
  },
  stockCategoryApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/stocker/api/category/${pk}` : `${routeBackOffice}/stocker/api/category/`
    return endpoint
  }
}

// << Management >>
export const routeManagementApp = {
  StocksApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/management/api/monthly-stock/${pk}/` : `${routeBackOffice}/management/api/monthly-stock/`;
    return endpoint
  },
  CostsApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/management/api/monthly-cost/${pk}/` : `${routeBackOffice}/management/api/monthly-cost/`;
    return endpoint
  },
  PaymentMethodApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/management/api/payment-method/${pk}/` : `${routeBackOffice}/management/api/payment-method/`;
    return endpoint
  }
}


// << Users >>
export const routeUsersApp = {
  SalonStaff() {
    return `${routeBackOffice}/users/salon-staff/`
  },
  UserProfileApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/users/api/user-profile/${pk}/` : `${routeBackOffice}/users/api/user-profile/`;
    return endpoint
  },
  SalonApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/users/api/salon/${pk}/` : `${routeBackOffice}/users/api/salon/`;
    return endpoint
  },
  CompanyApi(pk?: string) {
    const endpoint = pk ? `${routeBackOffice}/users/api/company/${pk}/` : `${routeBackOffice}/users/api/company/`;
    return endpoint
  },
}